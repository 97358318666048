<template>
  <v-container>
    <h5 class="subtitle-1 mb-4">
      Informe as datas para a geração da primeira fatura
    </h5>

    <v-form ref="form" lazy-validation v-model="valid">
      <v-row dense>
        <v-col
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="4"
          v-for="(item, index) in items"
          :key="index"
        >
          <BaseDatePicker
            required
            :rules="[required]"
            :disabled="!cannotEdit"
            :label="item.productType"
            v-model="item.firstInvoiceDate"
          />
        </v-col>
      </v-row>

      <v-divider class="my-5" />

      <v-row dense>
        <v-col class="text-right">
          <BaseButton @click="back" outlined color="primary" title="Voltar" />

          <template v-if="cannotEdit">
            <BaseButton
              color="primary"
              title="Salvar"
              @click="finalize"
              :disabled="!valid"
              v-permission="{
                roles: ['CC1', 'CC2'],
                operator: '!=='
              }"
            />
          </template>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { ImplementationService } from '@/services/api/implementation';

export default {
  mixins: [rulesMixin],

  props: {
    implementationId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    valid: true,
    items: []
  }),

  computed: {
    cannotEdit() {
      const { situation } = this.$route.params;

      return situation === 'Concluído' ? false : true;
    }
  },

  created() {
    this.search();
  },

  methods: {
    async search() {
      try {
        const implementationService = new ImplementationService();
        const { status, data } =
          await implementationService.getImplementationFirstInvoiceDates(
            this.implementationId
          );

        if (status === 200) {
          this.items = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async finalize() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const implementationService = new ImplementationService();
        const { status } =
          await implementationService.postImplementationFirstInvoiceDates({
            implementationId: this.implementationId,
            items: this.items
          });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    back() {
      this.$emit('back');
    }
  }
};
</script>
